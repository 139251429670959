<template lang="pug">
  v-container
    v-toolbar(
      color="primary"
      dense
      dark
      elevation="0"
    )
      span Stocks</span>
      v-spacer
      v-icon mdi-table
    v-data-table(
      :items="stockList"
      :headers="headers"
      dense
      :loading="stockGetting"
      :search="search"
    )
      template(v-slot:top)
        v-row
          v-col(cols="3")
            v-text-field(
              label="search"
              v-model="search"
              append-icon="mdi-magnify"
            )
      template(v-slot:item="{ item }")
        tr
          td {{ item.id }}
          td {{ item.stock_category_id }}
          td {{ item.product_name }}
          td {{ item.product_type }}
          td {{ item.brand_name }}
          td {{ item.description }}
          td
            base-tooltip(
              text
              x-small
              color="green"
              @click="$emit('update:stock-id', item.id)"
              tooltip="srp unit"
              bottom
            )
              v-icon mdi-hand-coin
            base-tooltip(
              text
              x-small
              color="yellow darken-3"
              @click="$emit('edit', item)"
              tooltip="edit"
              bottom
            )
              v-icon mdi-pencil
            base-tooltip(
              v-if="!item.feed_detail_count"
              text
              bottom
              x-small
              :color="item.default_detail ? 'blue darken-1' : 'blue-grey darken-4'"
              tooltip="price"
              @click="openStockDetailDialog(item.default_detail ? item.default_detail : { stockId: item.id })"
            )
              v-icon mdi-folder-search-outline
    v-dialog(
      v-model="stockDetailDialog"
      eager
      width="20vw"
    )
      v-card
        v-toolbar(
          color="primary"
          dense
          dark
          elevation="0"
        )
          span Default Detail
        v-container
          create-default-detail(
            @close="stockDetailDialog = false"
            v-model="defaultDetailData"
          )
</template>
<script>
import stockRepository from '@/repositories/stock.repository'
import { getVars, errorHandler } from '@/libs/api-helper.extra'
import searchDelay from '@/libs/searchDelay.extra'
import { mapState } from 'vuex'

const getStockDelay = searchDelay()

const tableVars = () => ({
  headers: [
    { text: 'Stock Code', value: 'id' },
    { text: 'Stock Category', value: 'stoch_category_id' },
    { text: 'Product Name', value: 'product_name' },
    { text: 'Product Type', value: 'product_type' },
    { text: 'Brand Name', value: 'brand_name' },
    { text: 'Description', value: 'description' },
    { text: 'Config', sortable: false },
  ],
  stockDetailDialog: false,
  defaultDetailData: {},
  search: null,
})

export default {
  name: 'StockTable',
  props: {
    stockId: String,
  },
  components: {
    // tooltipBtn: () => import('@/components/extra/TooltipBtn'),
    createDefaultDetail: () => import('./default-details/Create'),
  },
  created () {
    this.getStock()
    this.websocketEvents()
  },
  data () {
    return {
      ...tableVars(),
      ...getVars('stock'),
    }
  },
  computed: {
    ...mapState({
      echo: state => state.websocket.echo,
    }),
  },
  methods: {
    openStockDetailDialog (item) {
      this.defaultDetailData = item
      this.stockDetailDialog = true
    },
    getStock () {
      if (this.stockGetting) return
      this.stockGetting = true
      this.stockGetErrors = []
      getStockDelay(() => {
        const params = this.getStockIndexParams()
        stockRepository.index(params)
          .then(({ data }) => { this.stockList = data })
          .catch(e => { this.stockGetErrors = errorHandler(e) })
          .then(() => { this.stockGetting = false })
      })
    },
    getStockIndexParams () {
      const withCount = ['feedDetail'].join(',')
      return {
        with: 'defaultDetail',
        withCount,
      }
    },
    websocketEvents () {
      this.echo.private('database.event')
        .listen('DBStoreEvent', this.dBStoreEvent)
        .listen('DBUpdateEvent', this.dbUpdateEvent)
        .listen('DBDeleteEvent', this.dbDeleteEvent)
    },
    dbUpdateEvent ({ model, data }) {
      if (!model || this.$objectEmpty(data)) return
      if (model === 'Stock') {
        this.updateStockList(data)
        return
      }
      if (model === 'StockDefaultDetail') {
        this.stockDefaultDetailDBStoreEvent(data)
      }
    },
    dbDeleteEvent ({ model, id }) {
      if (!model || !id) return
      if (model === 'StockDefaultDetail') {
        this.stockDefaultDetailDBDeleteEvent(id)
      }
    },
    updateStockList (data) {
      this.stockList = this.stockList.map(item => {
        if (data.id === item.id) {
          return Object.assign({
            ...item,
            ...data,
          })
        }
        return Object.assign({ ...item })
      })
    },
    stockDefaultDetailDBDeleteEvent (id) {
      this.stockList = this.stockList.map(item => {
        if (item.default_detail && item.default_detail.id === Number(id)) return Object.assign({ ...item, default_detail: null })
        return Object.assign({ ...item })
      })
    },
    dBStoreEvent ({ model, data }) {
      if (!model || this.$objectEmpty(data)) return
      if (model === 'Stock') {
        this.stockDBStoreEvent(data)
        return
      }
      if (model === 'StockDefaultDetail') {
        this.stockDefaultDetailDBStoreEvent(data)
      }
    },
    stockDefaultDetailDBStoreEvent (data) {
      this.stockList = this.stockList.map(item => {
        if (item.id === data.stock_id) {
          return Object.assign({ ...item, default_detail: data })
        }
        return Object.assign({ ...item })
      })
    },
    stockDBStoreEvent (newStock) {
      this.insertStockList(newStock)
    },
    insertStockList (newStock) {
      this.stockList = this.stockList.objectInsertion(newStock, 'id', 'id')
        .map(item => Object.assign({ ...item }))
    },
  },
}
</script>
